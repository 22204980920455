<template>
  <b-card>
    <span class="title">
      Scooters
    </span>
    <card-statistic-circle-chart-colors
      :series="scooterStats.series"
      :labels="scooterStats.labels"
      :colors="scooterStats.colors"
    />
    <div class="scooters-div">
      <b-row
        v-for="(scooter,index) in scooterStatsList"
        :key="index"
        class="no-margin"
      >
        <b-col class="ride-line">
          <span
            class="circle-colored-icon"
            :style="'border-color:'+scooter.color"
          />
          <span class="ride-scooter-type">{{ scooter.scooter }}</span>
          <span class="percentage float-right">{{ scooter.percentage }}%</span>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>
<script>
import CardStatisticCircleChartColors from '@/views/components/statistics/CardStatisticCircleChartColors.vue'

export default {
  components: {
    CardStatisticCircleChartColors,
  },
  props: {
    scooterStatsList: {
      type: Array,
      default() {
        return []
      },
    },
    scooterStats: {
      type: Object,
      default() {
        return Object
      },
    },
  },
}
</script>
