<template>
  <div>
    <b-card>

      <div class="pl-2">
        <div class="ml-1">
          <span class="title">
            Total Scooters
          </span>
          <div class="subtitle">
            {{ moment(new Date()).format('YYYY-MM-DD') }}  <span class="mr-1"><span class="font-weight-bold">{{ scooterStatsTotalAmount }}</span> Scooter(s)</span>
          </div>
        </div>
      </div>

      <card-statistic-line-chart-colors-liner
        :series="totalScootersList.series"
        :loaded="isLoaded"
        :default-type="defaultPeriod"
        :default-interval="defaultInterval"
        :time-interval="false"
        :show-range-picker="false"
        :show-periods-picker="false"
        @reload-chart="loadCharts"
      />

    </b-card>
    <b-row>
      <b-col>
        <b-card class="statistics-icon-container">
          <b-col
            cols="2"
            class="float-left mr-1"
          >
            <div class="icon scooter-icon mr-1 float-left" />
          </b-col>
          <b-col
            cols="9"
            class="float-left data-container"
          >
            <b-row class="number">
              {{ statisticsItems[0].title }}
            </b-row>
            <b-row class="title">
              <div>Month to day</div>
            </b-row>
          </b-col>
        </b-card>
      </b-col>
      <b-col>
        <b-card class="statistics-icon-container">
          <b-col
            cols="2"
            class="float-left mr-1"
          >
            <div class="icon scooter-icon mr-1 float-left" />
          </b-col>
          <b-col
            cols="9"
            class="float-left data-container"
          >
            <b-row class="number">
              {{ statisticsItems[1].title }}
            </b-row>
            <b-row class="title">
              <div>Year to day</div>
            </b-row>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6">
        <b-card class="statistics-icon-container">
          <b-col
            cols="2"
            class="float-left mr-1"
          >
            <div class="icon odometer-icon mr-1 float-left" />
          </b-col>
          <b-col
            cols="9"
            class="float-left data-container"
          >
            <b-row class="number">
              {{ statisticsItems[2].title ? parseInt(statisticsItems[2].title).toLocaleString("en-US") : 0 }} KM
            </b-row>
            <b-row class="title">
              <div>Odometer</div>
            </b-row>
          </b-col>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import CardStatisticLineChartColorsLiner from '@/views/components/statistics/CardStatisticLineChartColorsLiner.vue'
import Moment from 'moment'

export default {
  components: {
    CardStatisticLineChartColorsLiner,
  },
  props: {
    totalRides: {
      type: Object,
      default() {
        return null
      },
    },
    statisticsItems: {
      type: Array,
      default() {
        return []
      },
    },
    scooterStatsTotal: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      moment: Moment,
      isLoaded: true,
      totalScootersList: {},
      statisticsItemsList: this.statisticsItems,
      scooterStatsTotalAmount: this.scooterStatsTotal,
      defaultPeriod: 'ytd',
      defaultInterval: 'monthly',
    }
  },
  mounted() {
    this.loadCharts()
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.refreshChart)
  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged')
  },
  methods: {
    // showLoader = true,dates = null, interval = null, period = null, applyDates = false
    refreshChart(showLoader = true,dates = null, interval = null, period = null, applyDates = false) {
      this.selectedDatesShown = dates
      this.loadCharts(showLoader, period ,interval, this.selectedDatesShown, applyDates)
    },

    loadCharts(showLoader = true, period = null, interval = null, dates = null, applyDates = false) {
      if(showLoader) {
        this.statsLoading = true
        this.isLoaded = false
      }
      this.defaultPeriod = period ?? this.defaultPeriod
      this.defaultInterval = interval ?? this.defaultInterval

      const postData = {}
      if (dates && applyDates) {
        if (dates.start_date) {
          postData.start_date = dates.start_date
        }
        if (dates.end_date) {
          postData.end_date = dates.end_date
        }
      }

      postData.interval = this.defaultInterval
      postData.type = 'scooters_count'
      this.$http.post(this.$resources.scooters.calculations.charts, postData).then(response => {
        const { status, data: { data: {scooters : {stats}} } } = response
        const dataValues = Object.values(stats)
        if (status === 200) {
          this.totalScootersList = {
            series: [{
              data: dataValues.map(stat => ({ x: stat.label, y: stat.count })),
              name: 'Total Scooters',
            }],
          }
          this.scooterStatsTotalAmount = dataValues.reduce((total, stat) => total + stat.count, 0)
        }
        this.isLoaded = true
      })
    },
  },
}
</script>
