<template>
  <div>

    <template v-if="scooterStatsLoading && statsLoading">
      <b-card class="rides-list-charts-loader">
        <widget-loader />
      </b-card>
    </template>

    <b-row v-else>
      <b-col
          cols="12"
          md="12"
          lg="4"
          class="pie"
      >
        <!-- we have to unify this component later to not repeat ourselves for (cutomers, rides, scooters)-->
        <scooter-stats-list
            :scooter-stats-list="scooterStatsListData"
            :scooter-stats="scooterStats"
        />
      </b-col>
      <b-col
          cols="12"
          sm="12"
          md="12"
          lg="8"
          class="lines"
      >
        <scooters-statistics
            :total-rides="totalRides"
            :statistics-items="statisticsItems"
            :scooter-stats-total="scooterStatsTotal"
            :time-interval="true"
        />
      </b-col>
    </b-row>
    <b-card class="scooter-list-wrapper">
      <h4>
        Scooter list
      </h4>
      <b-col>
        <new-table
            :export-file-name="exportFileName"
            :columns="columns"
            :items="items"
            :data-source="scootersStore"
            table-name="scooters-list"
            router-link="view-scooter"
        />
      </b-col>
    </b-card>
  </div>
</template>

<script>

import ScooterStatsList from '@/views/scooters/components/scooters-list/ScootersStatsList.vue'
import ScootersStatistics from '@/views/scooters/components/scooters-list/ScootersStatistics.vue'
import NewTable from '@/views/new-table-ssr.vue'
import {
  BCard, VBTooltip, BCol, BRow,
} from 'bootstrap-vue'
import moment from 'moment'
import CustomStore from 'devextreme/data/custom_store'
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    NewTable,
    BCard,
    BCol,
    BRow,
    ScooterStatsList,
    ScootersStatistics,
    WidgetLoader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      scootersStore: null,
      default_scooter_color: '#cccccc',
      loading: false,
      scooterStatsLoading: true,
      items: [],
      exportFileName: `scootersList_${moment(new Date()).format('YYYY-MM-DD')}`,
      scooterStats: {
        series: [],
        labels: [],
        colors: [],
      },
      scooterStatsTotal: 0,
      scooterStatsListData: [],
      statisticsItems: [],
      statsByMonth: null,
      statsLoading: true,
      oldFilter: '',
      scootersColors: {},
      scooterTypes: [],
      colors: null,
      ridesStats: {},
      totalRides: {
        series: [{
          name: 'Total Rides',
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5],
        }],
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      fields: [
        // { key: 'name', label: 'name', sortable: true },
        {key: 'type', label: 'type', sortable: true},
        {key: 'customer_email', label: 'customer', sortable: true},
        {key: 'serial', label: 'serial', sortable: true},
        {key: 'odometer', label: 'Odometer', sortable: true},
        {key: 'view_btn', label: 'View'},
      ],

      columns: [
        {
          label: 'Type',
          data: 'type',
        },
        {
          label: 'Date first registered',
          data: 'created_at',
          calculateCellValue(data) {
            return moment.utc(data.created_at).local().format('ll hh:mma')
          }
        },
        {
          label: 'Emails history',
          data: 'customer_email',
          sortable: false,
          calculateCellValue(data) {
            return data.customer_email ? data.customer_email : 'No connections'
          },
        },
        {
          label: 'Serial',
          data: 'serial',
        },
        {
          label: 'Odometer',
          data: 'odometer',
          custom_template: {
            template_name: 'scooters.odometer',
          },
          calculateCellValue(data) {
            return parseFloat(data.odometer)
          },
        },
      ],
      page_name: 'scooters-list'
    }
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.loadPageData)
  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged', this.loadPageData)
  },
  mounted() {
    this.$store.commit('menuConfig/SET_PAGE_TITLE', 'Scooters')
    this.colors = Object.values(this.scootersColors)
    const postData = this.$getPostData({}, null)
    this.loadPageData(true, postData)
    this.mountFilerData(postData)
  },
  methods: {
    loadPageData(showLoader = true, dates = null) {
      this.loadStats(showLoader, dates)
      this.loadScooters(dates)
    },
    loadScooters(dates = null) {
      this.scootersStore = new CustomStore({
        key: 'id',
        load: loadOptions => {
          if (loadOptions.filter !== undefined && loadOptions.filter.length > 0) {
            this.setFilterData(this.page_name, loadOptions)
          } else {
            this.setFilterData(this.page_name, null)
          }


          if (loadOptions.sort !== null && loadOptions.sort.length > 0) {
            this.setSortData(this.page_name, loadOptions.sort)
          } else {
            this.setSortData(this.page_name, null)
          }

          // loadOptions.sort = { selector: 'start_time', desc: true }
          let postData = {
            search_filters: loadOptions,
          }
          if (dates) {
            postData = this.$getPostData(postData, dates)
          }

          return this.$http.post(this.$resources.scooters.index, postData)
              .then(data => ({
                data: data.data.data.rows,
                totalCount: data.data.data.pagination.total,
              }))
              .catch(error => {
                console.log(error)
              })
        },
      })
    },
    mountFilerData() {
      this.getFilterData(this.page_name, this.columns)
    },
    loadStats(showLoader = true, postData = null) {
      if (showLoader) {
        this.statsLoading = true
      }

      this.$http.post(this.$resources.scooters.calculations.stats, postData).then(response => {
        const {data: {status, data}} = response
        if (status === 200) {
          this.statisticsItems = [
            {
              title: data.current_month,
            },
            {
              title: data.current_year,
            },
            {
              title: Math.round(data.odometer),
            },
          ]
          this.colors = data.colors
        }
      }).then(() => {
        if (showLoader = true) {
          this.scooterStatsLoading = true
        }

        postData.type = 'scooter_types'
        this.$http.post(this.$resources.scooters.calculations.charts, postData).then(avgStatsResponse => {
          const {data: {status, data}} = avgStatsResponse
          if (status === 200) {
            this.scooterStatsListData = []
            this.scooterStats = {
              series: [],
              labels: [],
              colors: [],
            }
            this.scooterStatsTotal = data.series.reduce((a, b) => a + b, 0)

            data.series.forEach((state, index) => {
              if (data.colors[index].type.trim() !== '') {
                const percentage = ((state / this.scooterStatsTotal) * 100).toFixed(2)
                this.scooterStatsListData.push({
                  scooter: data.colors[index].type,
                  count: state,
                  color: data.colors[index].color,
                  percentage,
                })

                this.scooterStats.labels.push(data.colors[index].type)
                this.scooterStats.series.push(state)

                if (typeof data.colors[index].color !== undefined) {
                  this.scooterStats.colors.push(data.colors[index].color)
                } else {
                  this.scooterStats.colors.push(this.default_scooter_color)
                }

              }
            })
            if (showLoader) {
              this.statsLoading = false
              this.scooterStatsLoading = false
            }
          }
          this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
        })
      })
    },
    load(filter = null, postData = null) {
      this.loading = true
      this.$http.get(this.$resources.scooters.index, {
        params: {
          filter,
          postData,
        },
      }).then(response => {
        const {data: {status, data}} = response
        if (status === 200) {
          this.oldFilter = filter
          this.items = data.rows
          this.loading = false
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/views/scooters/scss/scootersList.scss';
</style>
